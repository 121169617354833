// import { Application } from '@splinetool/runtime'
//
// const canvas = document.getElementById('mobile-app-model-wrapper')
// const spline = new Application(canvas)
// spline
//     .load('https://prod.spline.design/lPiskBaXfUUkx9we/scene.splinecode')
//     .load(SPLINE_URL_MOBILE_STORE)
// console.log(spline)
// // spline.setSize(600, 800)

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const mobileAppSectionElement = document.getElementById('mobile-app');

const moduleAnimation = gsap.matchMedia()

moduleAnimation.add('(min-width: 1280px)', () => {
    const moduleMobileAppST = ScrollTrigger.create({
        trigger: mobileAppSectionElement,
        start: 'top top',
        pin: true,
        pinSpacing: false,
    })

    return () => {
        moduleMobileAppST.kill()
    }
})