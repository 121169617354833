import '../css/styles.scss'
import './banner/banner.js'
import './logosSlider/logosSlider.js'
import './imagesOnScroll/imagesOnScroll.js'
import './features/features.js'
import './mobileApp/mobileApp.js'
import './menu/menu.js'
import './3d/3d.js'
import './references/references.js'
import { webVitals } from '../vitals.js'

webVitals()
