const nav = document.querySelector('nav')
const navItemsWrapper = document.querySelector('div#nav-items')
const menuButton = document.querySelector('#menu-button')
const navOverlay = document.querySelector('#nav-overlay')
const textMenuSpan = document.querySelector('span.menu-button-text')
const featuresStickyTitle = document.querySelector('div.features-sticky-title')
const featuresStickyTitleMobile = document.querySelector('div.features-sticky-title-mobile')
const featuresStickyNav = document.querySelector('div.features-nav')
const featuresTopGradientWrapper = document.getElementsByClassName('features-top-blend')[0]
const featuresTopGradientWrapperMobile = document.getElementsByClassName('features-top-blend-mobile')[0]
const menuText = textMenuSpan.innerHTML
const closeMenuText = textMenuSpan.getAttribute('data-text-close')
const links = [...document.querySelectorAll('div#pop-up-menu > div.links > a')]
links.forEach((link) => link.onclick = hideMenu)

let lastKnownScrollPosition = 0
let previousScrollPosition = 0
let ticking = false
let menuOpen = false

function onScroll(value) {
  if (!nav) return
  const delta = value - previousScrollPosition
  if (value > 0) {
    nav.classList.add('scrolled')
  } else {
    nav.classList.remove('scrolled')
  }
  if (delta > 0 && value > 0 && !menuOpen) {
    nav.classList.add('hide')
    featuresStickyTitle.classList.add('scroll-down')
    featuresStickyNav.classList.add('scroll-down')
    featuresTopGradientWrapper.classList.add('scroll-down')
    if(featuresTopGradientWrapperMobile.classList.contains('on-enter')) {
      featuresTopGradientWrapperMobile.classList.add('scroll-down')
      featuresStickyTitleMobile.classList.add('scroll-down')
    }
  } else {
    nav.classList.remove('hide')
    featuresStickyTitle.classList.remove('scroll-down')
    featuresStickyNav.classList.remove('scroll-down')
    featuresTopGradientWrapper.classList.remove('scroll-down')
    featuresTopGradientWrapperMobile.classList.remove('scroll-down')
    featuresStickyTitleMobile.classList.remove('scroll-down')
  }
}

function showMenu() {
  navItemsWrapper.classList.add('menu-open')
  navOverlay.classList.add('visible')
  navOverlay.addEventListener('click', hideMenu, true)
  textMenuSpan.innerHTML = closeMenuText
  menuOpen = true
}

function hideMenu() {
  navItemsWrapper.classList.remove('menu-open')
  navOverlay.classList.remove('visible')
  navOverlay.removeEventListener('click', hideMenu, true)
  textMenuSpan.innerHTML = menuText
  menuOpen = false
}

function toggleMenu() {
  if (!nav) return
  if (!menuOpen) {
    showMenu()
  } else {
    hideMenu()
  }
}

document.addEventListener('scroll', (event) => {
  previousScrollPosition = lastKnownScrollPosition
  lastKnownScrollPosition = window.scrollY
  if (!ticking) {
    window.requestAnimationFrame(() => {
      onScroll(lastKnownScrollPosition)
      ticking = false
    })
    ticking = true
  }
})
menuButton.addEventListener('click', toggleMenu, true)
