const openModalBtn = document.getElementById('play-button')
const closeModalBtn = document.getElementById('closeModal')
const videoModal = document.getElementById('videoModal')
const videoFrame = document.getElementById('videoFrame')

openModalBtn.addEventListener('click', () => {
  videoModal.style.display = 'block'
  videoFrame.src = 'https://www.youtube.com/embed/st0-OL5INXs'
})

window.addEventListener('click', (event) => {
  if (event.target === videoModal) {
    videoModal.style.display = 'none'
    videoFrame.src = ''
  }
})

window.addEventListener('keydown', (event) => {
  if (event.keyCode === 27) {
    videoModal.style.display = 'none'
    videoFrame.src = ''
  }
})
