import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import lottie from 'lottie-web'
import AnimationMobile from '../../../static/assets/imagesOnScroll/lottie-animation-mobile.json'
import Animation from '../../../static/assets/imagesOnScroll/lottie-animation.json'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const isDesktopDevice = window.matchMedia('(min-width: 1280px)').matches
let animationPlayElement
let animationContainer

if (isDesktopDevice) {
  animationPlayElement = document.getElementById('small-header-with-lottie')
  animationContainer = document.getElementById('lottie-wrapper')
  const sliderTriggerElement = document.getElementById('slider-container-st')
  const previewImageElement = document.getElementById('preview-image-wrapper')
  const pinnedHeadingElement = document.getElementById('pinned-slider-heading')
  const pinnedSlidesWrapperElement = document.getElementById('pinned-slides-wrapper')
  const pinnedSlidesElements = gsap.utils.toArray('.pinned-slide')
  const additionalImagesElements = gsap.utils.toArray('.pinned-additional-image')
  const progressBarAndButtonWrapperElement = document.getElementById('progress-bar-and-button-wrapper')
  const progressBarElement = document.getElementById('progess-bar-gradient')
  const goToTopButtonElement = document.getElementById('pinned-button-wrapper')
  const additionalImagesWrapper = document.getElementById('additional-images-wrapper')

  ScrollTrigger.create({
    trigger: sliderTriggerElement,
    pin: true,
    pinSpacing: true,
    id: 'slider',
    scrub: 1,
    start: 'top top',
    end: '400%',
    onUpdate: (state) => {
      let currentActiveIndex
      const { progress } = state
      progressBarElement.style.transform = `scaleX(${progress}`
      currentActiveIndex = progress >= 0 && progress < 0.25 ? 0 : progress >= 0.25 && progress < 0.5 ? 1 : progress >= 0.5 && progress < 0.75 ? 2 : progress >= 0.75 && progress <= 1 ? 3 : 4
      pinnedSlidesElements.forEach((item, key) => {
        if (key !== currentActiveIndex) {
          item.classList.remove('visible')
        } else {
          item.classList.add('visible')
        }
      })
      additionalImagesElements.forEach((item, key) => {
        if (key !== currentActiveIndex) {
          item.classList.remove('visible')
        } else {
          item.classList.add('visible')
        }
      })
      if (progress > 0 && progress < 0.25) {
        previewImageElement.style.transform = 'translate(0, -40%) scale(0.85)'
      } else if (progress >= 0.25 && progress < 0.5) {
        previewImageElement.style.transform = 'translate(10%, -40%) scale(0.85)'
      } else if (progress >= 0.5 && progress < 0.75) {
        previewImageElement.style.transform = 'translate(-10%, -40%) scale(0.85)'
      } else if (progress >= 0.75 && progress <= 1) {
        previewImageElement.style.transform = 'translate(-15%, -40%) scale(0.85)'
      }
    },
    onEnter: () => {
      previewImageElement.classList.add('sticky')
      pinnedHeadingElement.classList.add('visible')
      pinnedSlidesWrapperElement.classList.add('visible')
      progressBarAndButtonWrapperElement.classList.add('visible')
      additionalImagesWrapper.classList.add('visible')
    },
    onLeaveBack: () => {
      previewImageElement.classList.remove('sticky')
      pinnedHeadingElement.classList.remove('visible')
      pinnedSlidesWrapperElement.classList.remove('visible')
      progressBarAndButtonWrapperElement.classList.remove('visible')
      additionalImagesWrapper.classList.remove('visible')
      previewImageElement.style.transform = 'translate(-50%, -50%) scale(1)'
    },
  })

  goToTopButtonElement.addEventListener('click', () => {
    const myST = ScrollTrigger.getById('slider')
    gsap.to(window, { scrollTo: { y: myST.start - 1 }, duration: 0.7 })
  })
} else {
  animationPlayElement = document.getElementById('small-header-with-lottie-mobile')
  animationContainer = document.getElementById('lottie-wrapper-mobile')
}

const animation = lottie.loadAnimation({
  container: animationContainer,
  renderer: 'svg',
  loop: false,
  animationData: isDesktopDevice ? Animation : AnimationMobile,
})

animationPlayElement.addEventListener('mouseenter', () => {
  animation.setDirection(1)
  animation.setSpeed(3)
  animation.play()
})

animationPlayElement.addEventListener('mouseleave', () => {
  animation.setSpeed(3)
  animation.setDirection(-1)
  animation.play()
})
