import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import EmblaCarousel from 'embla-carousel'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const isDesktopDevice = window.matchMedia('(min-width: 1280px)').matches
if (isDesktopDevice) {
  const links = gsap.utils.toArray('div.features-nav div.features-nav-element')
  links.forEach((a) => {
    const element = document.querySelector(`#${a.getAttribute('data-id')}`)
    const linkST = ScrollTrigger.create({
      trigger: element,
      start: 'top top',
    })
    ScrollTrigger.create({
      trigger: element,
      start: 'top center',
      end: 'bottom center',
      onToggle: (self) => self.isActive && setActive(a),
    })
    a.addEventListener('click', (e) => {
      e.preventDefault()
      gsap.to(window, {
        duration: 0.6,
        ease: 'power1.inOut',
        scrollTo: linkST.start - 300,
      })
    })
  })

  function setActive(link) {
    links.forEach((el) => el.classList.remove('active'))
    link.classList.add('active')
  }

  const stickyNavWrapper = document.getElementById('features-sticky-nav')
  const featuresTopGradientWrapper = document.getElementsByClassName('features-top-blend')[0]
  ScrollTrigger.create({
    trigger: stickyNavWrapper,
    // start: 'top top+=70',
    start: 'top top+=100',
    endTrigger: '#integration-and-customization',
    end: 'bottom top',
    pin: true,
    pinSpacing: false,
  })

  ScrollTrigger.create({
    trigger: featuresTopGradientWrapper,
    start: 'top top',
    endTrigger: '#integration-and-customization',
    end: 'bottom top+=170',
    pin: true,
    pinSpacing: false,
  })

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: stickyNavWrapper,
      start: 'top top+=300',
      end: '200',
      onEnter: () => {
        tl.to('.features-sticky-title-first', { autoAlpha: 0, ease: 'power2.inOut', duration: 0.2 })
          .to('.features-sticky-title', { autoAlpha: 1, ease: 'power2.inOut', duration: 0.3 })
      },
      onEnterBack: () => {
        tl.to('.features-sticky-title', { autoAlpha: 0, ease: 'power2.out', duration: 0.3 })
          .to('.features-sticky-title-first', {
            autoAlpha: 1, yPercent: 0, ease: 'power2.out', duration: 0.2,
          })
      },
    },
  })

  const featuresContentContainers = gsap.utils.toArray('div.section-content-container')

  function setActiveCategoryItem(link, section, leftNavCount, leftNavIndex, leftNavItems) {
    if (section.contains(link)) {
      const currentSectionFeaturesRightText = section.querySelectorAll('div.right-text-wrapper div.single-right-text-wrapper')
      const currentSectionImages = section.querySelectorAll('div.content-images-wrapper img.single-content-img')
      const currentSectionActiveLine = section.querySelectorAll('div.left-navigation-wrapper div.line-elevator div.active-line')
      const currentRightTextSliderWrapper = section.querySelectorAll('div.right-text-slider')

      const windowWidth = window.innerWidth
      const movementOffsetOneRow = windowWidth < 1600 ? '29px' : '32px'
      const movementOffset = windowWidth < 1600 ? '14.5px' : '16px'

      leftNavItems.forEach((el) => el.classList.remove('active'))
      link.classList.add('active')
      currentSectionActiveLine[0].style.transform = `translateY(calc(${leftNavIndex} * ${movementOffsetOneRow} + ${movementOffset}))`
      currentRightTextSliderWrapper[0].style.transform = `translateY(calc(${leftNavIndex} * 260px * -1))`
      const currentLinkDataId = link.getAttribute('data-id')
      const imageElement = document.getElementById(`img-${currentLinkDataId}`)
      const textElement = document.getElementById(`text-${currentLinkDataId}`)
      currentSectionImages.forEach((el) => el.classList.remove('active'))
      imageElement.classList.add('active')
      currentSectionFeaturesRightText.forEach((el) => el.classList.remove('active'))
      textElement.classList.add('active')
    }
  }

  featuresContentContainers.forEach((el) => {
    const leftNavItems = el.querySelectorAll('div.left-navigation-wrapper p.left-nav-element')
    const leftNavItemsCount = leftNavItems.length
    leftNavItems.forEach((item, index) => {
      item.addEventListener('click', () => setActiveCategoryItem(item, el, leftNavItemsCount, index, leftNavItems))
    })
  })
} else {
  const stickyNavWrapperMobile = document.getElementById('features-sticky-headings-mobile')
  const featuresTopGradientWrapperMobile = document.getElementsByClassName('features-top-blend-mobile')[0]
  const windowWidth = window.innerWidth
  const mobileStart = 'top top'
  // const mobileEnd = 'bottom top+=120'
  const mobileEnd = 'bottom top+=30'
  // const tabletStart = 'top top+=70'
  const tabletStart = 'top top'
  // const tabletEnd = 'bottom top+=150'
  const tabletEnd = 'bottom top+=20'
  ScrollTrigger.create({
    trigger: featuresTopGradientWrapperMobile,
    // start: 'top top',
    start: windowWidth < 768 ? mobileStart : tabletStart,
    endTrigger: '#products-wrapper-mobile',
    // end: 'bottom top+=70',
    // end: 'bottom top+=120',
    end: windowWidth < 768 ? mobileEnd : tabletEnd,
    pin: true,
    // markers: true,
    pinSpacing: false,
  })

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: stickyNavWrapperMobile,
      // trigger: featuresTopGradientWrapperMobile,
      start: 'top top+=20',
      end: 'top top+=20',
      // end: 'bottom top+=150',
      // endTrigger: '#products-wrapper-mobile',
      // end: 'bottom top+=170',
      // markers: true,
      // pin: true,
      // pinSpacing: false,
      // end: '200',
      onEnter: () => {
        featuresTopGradientWrapperMobile.classList.add('on-enter');
        console.log('enter');
        // tl.to('.features-sticky-title-first-mobile', { autoAlpha: 0, ease: 'power2.inOut', duration: 0.2 },0)
        tl.to('.features-sticky-title-first-mobile', { autoAlpha: 0, ease: 'power2.inOut', duration: 0.2 })
          .to('.features-sticky-title-mobile', { autoAlpha: 1, ease: 'power2.inOut', duration: 0.3 })
      },
      onEnterBack: () => {
        featuresTopGradientWrapperMobile.classList.remove('on-enter');
        console.log('enter back')
        tl.to('.features-sticky-title-mobile', { autoAlpha: 0, ease: 'power2.out', duration: 0.3 })
          .to('.features-sticky-title-first-mobile', {
            autoAlpha: 1, yPercent: 0, ease: 'power2.out', duration: 0.2,
          })
      },
    },
  })

  document.addEventListener('DOMContentLoaded', () => {
    const categoriesContainer = document.querySelector('#features-mobile .categories')
    const productsContainer = document.querySelector('.products')
    const arrowPrevious = document.querySelector('div.arrow-left')
    const arrowNext = document.querySelector('div.arrow-right')
    const supplyManagementItems = document.querySelectorAll('div.single-feature-slide.sm').length || 0
    const billingItems = document.querySelectorAll('div.single-feature-slide.bl').length || 0
    const failureDiscoveryItems = document.querySelectorAll('div.single-feature-slide.fd').length || 0
    const integrationAndCustomizationItems = document.querySelectorAll('div.single-feature-slide.ic').length || 0
    let categoryCarousel
    let productsCarousel

    const categories = [
      {
        id: 0,
        productsCount: supplyManagementItems,
      },
      {
        id: 1,
        productsCount: billingItems,
      },
      {
        id: 2,
        productsCount: failureDiscoveryItems,
      },
      {
        id: 3,
        productsCount: integrationAndCustomizationItems,
      },
    ]

    categoryCarousel = EmblaCarousel(categoriesContainer, {
      dragFree: false,
      align: 'start',
    })
    productsCarousel = EmblaCarousel(productsContainer, {
      dragFree: false,
      align: 'start',
    })
    arrowPrevious.addEventListener('click', () => categoryCarousel.scrollPrev())
    arrowNext.addEventListener('click', () => categoryCarousel.scrollNext())

    const handleArrowsVisibility = (index) => {
      if (index === 0) {
        arrowPrevious.style.opacity = '0'
      } else {
        arrowPrevious.style.opacity = '1'
      }
      if (index === categories.length - 1) {
        arrowNext.style.opacity = '0'
      } else {
        arrowNext.style.opacity = '1'
      }
    }

    const changeCategory = (index) => {
      categoryCarousel.scrollTo(index)
      handleArrowsVisibility(index)
    }
    const changeProduct = (index) => {
      productsCarousel.scrollTo(index)
    }

    const handleMoveCategory = (index) => {
      let accumulatedProductsCount = 0
      categories.forEach((category, i) => {
        if (index >= accumulatedProductsCount && index < accumulatedProductsCount + category.productsCount) {
          changeCategory(i)
        }
        accumulatedProductsCount += category.productsCount
      })
    }
    let prevCategoryIndex
    categoryCarousel.on('select', () => {
      const selectedCategoryIndex = categoryCarousel.selectedScrollSnap()
      const sum = categories.slice(0, selectedCategoryIndex).reduce((total, category) => total + category.productsCount, 0)
      const currentCategoryOffset = categories[selectedCategoryIndex].productsCount - 1
      const finalMoveToIndex = selectedCategoryIndex < prevCategoryIndex ? sum + currentCategoryOffset : sum
      changeProduct(finalMoveToIndex)
      handleArrowsVisibility(selectedCategoryIndex)
      prevCategoryIndex = selectedCategoryIndex
    })
    let prevProductIndex
    productsCarousel.on('select', () => {
      const selectedProductIndex = productsCarousel.selectedScrollSnap()
      handleMoveCategory(selectedProductIndex)

      prevProductIndex = selectedProductIndex
    })
    categoryCarousel.reInit()
    productsCarousel.reInit()
  })
}
