import { Application } from '@splinetool/runtime'

// make sure you have a canvas in the body
const canvas = document.getElementById('canvas3d')
// start the application and load the scene
const spline = new Application(canvas)
// console.log(SPLINE_URL)
spline.load(SPLINE_URL)
// spline.load(SPLINE_URL, {
//     credentials: 'include',
//     mode: 'no-cors',
// })
// console.log(spline)
