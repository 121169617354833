import EmblaCarousel from 'embla-carousel'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import setupProgressBar from './progressBar.js'

gsap.registerPlugin(ScrollTrigger)
const emblaTarget = document.querySelector('#references-wrapper ')
const scrollTarget = document.querySelector('#references-section')
const progressBarNode = document.querySelector('.progress-bar-inner')
const agent = document.querySelector('#references-agent ')

const options = {
  active: true,
  dragFree: false,
  align: 'start',
  watchSlides: false,
  breakpoints: {
    '(min-width: 1280px)': {
      active: false,
    },
  },
}
const embla = EmblaCarousel(emblaTarget, options)
const { applyProgress, removeProgress } = setupProgressBar(
  embla,
  progressBarNode,
)
embla
  .on('init', applyProgress)
  .on('reInit', applyProgress)
  .on('scroll', applyProgress)
  .on('destroy', removeProgress)

const agentAnimation = gsap.matchMedia()

agentAnimation.add('(min-width: 1280px)', () => {
  const agentST = ScrollTrigger.create({
    trigger: scrollTarget,
    start: 'top 20%',
    end: 'bottom top',
    onUpdate: (self) => {
      if (!agent) return
      agent.style.setProperty('--translateY', `${Math.max(window.scrollY - self.start, 0).toString()}px`)
    },
  })

  return () => {
    agentST.kill()
    agent.style.setProperty('--translateY', '0px')
  }
})
